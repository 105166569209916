import React, {useEffect, useState, useContext } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../helpers/AuthContext";

function ProfilePage() {
  let { id } = useParams();
  const [username, setUsername] = useState('');
  const [listOfPosts, setListOfPosts] = useState([]);
  let history = useNavigate();
  const { authState } = useContext(AuthContext);

  useEffect(() => {
    
    axios.get(`https://server.slwazi.com/auth/basicinfo/${id}`).then((response) => {
      setUsername(response.data.username);
    });

    axios.get(`https://server.slwazi.com/posts/byuserId/${id}`).then((response) => {
      setListOfPosts(response.data);
    });
  }, []);

  return (
    <div className='profilePageContainer'>
      <div className='basicInfo'><h1>Username: {username}</h1>
      { authState.username === username &&(
         <button onClick={() => {history('/changepassword/')}}
         >Change password</button>)}
      </div>
      <div className='listOfPosts'>
      {listOfPosts.map((value, key) => {
    
    return (
       <div className='post' key={key} >
         <div className='title'>{value.title}</div>
         <div className='body' onClick={() => {history(`/post/${value.id}`)}}>{value.postText}</div>
         <div className='footer'>
         <div className="username">{value.username}</div>
         <div className="buttons">
         
         <label>{value.Likes.length}</label>
         </div>
         </div>
         
       </div>
    );
   })}
      </div>
    </div>
  )
}

export default ProfilePage