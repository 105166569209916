import React from 'react'
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate  } from 'react-router-dom';

const Register = () => {
    let history = useNavigate();

    const initialValue ={
        username:"",
        password:""
    }

    

const onSubmit = (data) => {
    axios.post("https://server.slwazi.com/auth", data).then((response) =>{
    // redirect the page    
    history('/');
     });
}


const validationSchema = Yup.object().shape({
    
   
    username: Yup.string().min(3).max(15).required("Username is required"),
    password: Yup.string().min(3).max(20).required("Password is required")
});

  return (
    <div className='CreatePostPage'>
        <Formik initialValues={initialValue} onSubmit={onSubmit} validationSchema={validationSchema} >
            <Form className='formContainer'>

                <label>Username: </label>
                <ErrorMessage name="username" component="span"/>
                <Field 
                id="inputCreatePost" 
                autocomplete="off"
                name="username" 
                placeholder="(Ex. John12..." />

                <label>Password: </label>
                <ErrorMessage name="password" component="span"/>
                <Field 
                id="inputCreatePost"
                type="password"
                name="password"
                placeholder="(You password..." />

                <button type='submit'>Register</button>
            </Form>
        </Formik>
    </div>
  )
}
export default Register