import './App.css';
import {BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from './pages/Home';
import CreatePost from './pages/CreatePost';
import Post from './pages/Post';
import Login from './pages/Login';
import Register from './pages/Register';
import { AuthContext } from './helpers/AuthContext';
import { useState, useEffect } from 'react';
import axios from 'axios';
import PageNotFound from './pages/PageNotFound';
import ProfilePage from './pages/ProfilePage';
import ChangePassword from './pages/ChangePassword';


function App() {

  const [authState, setAuthState] = useState({ username: "", id: 0, status: false});

  useEffect(() => {

    axios.get("https://server.slwazi.com/auth/auth", { headers: {
      accessToken: localStorage.getItem("accessToken"),
    }}).then((response) =>{
      if(response.data.error){
        setAuthState({ ...authState, status:false });
      }else{
        setAuthState({
          username: response.data.username,
          id: response.data.id,
          status: true
        });
      }

    });
 
  }, []);

  const logout = () => {
    localStorage.removeItem("accessToken");
    setAuthState({ username: "", id: 0, status: false});
  }

  return (
    <div className="App">
      <AuthContext.Provider value={{ authState, setAuthState}}>
     <Router>
      <div className='navbar'>
        <div className="links">
         
          {!authState.status ? (
                <>
                  <Link to="/login" >Login</Link>
                  <Link to="/register" >Registration</Link>
                </>
              ) : (
                <>
                <Link to="/" >Home</Link> 
                <Link to="/createpost" >Create a Post</Link>
                </>
              )}
            </div>
            <div className="loggedInContainer">
              <h1>{authState.username} </h1>
              {authState.status && <button onClick={logout}> Logout</button>}
            </div>
      
      </div>
      
      <Routes>
        <Route path="/" exact Component={Home} />
        <Route path="/createpost" exact Component={CreatePost} />
        <Route path="/post/:id" exact Component={Post} />
        <Route path="/login" exact Component={Login} />
        <Route path="/register" exact Component={Register} />
        <Route path='/profile/:id' exact Component={ProfilePage} />
        <Route path="/changepassword" exact Component={ChangePassword} />
        <Route path='*' exact Component={PageNotFound} />
       
      </Routes>
    </Router>
    </AuthContext.Provider>
    </div>
  );
}

export default App;
