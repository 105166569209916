import React , { useContext, useEffect } from 'react'
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate  } from 'react-router-dom';
import { AuthContext } from "../helpers/AuthContext";

const CreatePost = () => {
    
    let history = useNavigate();
    const { authState } = useContext(AuthContext);

    const initialValue ={
        title:"",
        postText:"",
       
    }

useEffect(() => {
 if(!localStorage.getItem('accessToken')){
    history('/login');
 }
}, []); 

const onSubmit = (data) => {
    axios.post("https://server.slwazi.com/posts", data, { headers: {
        accessToken: localStorage.getItem("accessToken")},
    })
    .then((response) =>{
    // redirect the page    
    history('/');
     });
}


const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    postText: Yup.string().required("Post text is required"),
   
});

  return (
    <div className='CreatePostPage'>
        <Formik initialValues={initialValue} onSubmit={onSubmit} validationSchema={validationSchema} >
            <Form className='formContainer'>

                <label>Title: </label>
                <ErrorMessage name="title" component="span"/>
                <Field 
                id="inputCreatePost" 
                autoComplete="off"
                name="title" 
                placeholder="(Ex. Title..." />

                <label>Post: </label>
                <ErrorMessage name="postText" component="span"/>
                <Field 
                id="inputCreatePost" 
                name="postText"
                placeholder="(Ex. Post ..." />

               
                <button type='submit'>Create Post</button>
            </Form>
        </Formik>
    </div>
  )
}

export default CreatePost