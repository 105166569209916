import React , {useContext} from 'react'
import axios from "axios";
import { useEffect, useState} from "react";
import { useNavigate  } from 'react-router-dom';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { AuthContext } from "../helpers/AuthContext";


function Home() {
 const [listOfPosts, setListOfPosts] = useState([]);
 const [likedPosts, setLikedPosts] = useState([]);
 let history = useNavigate();
 const { authState } = useContext(AuthContext);

  useEffect(() =>{
    if(!localStorage.getItem('accessToken')){
      history('/login');
    }else{
      
      axios.get("https://server.slwazi.com/posts", {headers: 
      {accessToken: localStorage.getItem('accessToken')}}).then((response) =>{
        setListOfPosts(response.data.listOfPosts);
        setLikedPosts(response.data.likedPosts.map((like) => {
         return like.PostId;
        }));
      });
    }
      
  }, [authState.status, history]);

  const likePost = (postId) => {
    axios.post("https://server.slwazi.com/likes", {PostId:postId}, {headers: 
    {accessToken: localStorage.getItem('accessToken')}}).then((response) => {
     

      setListOfPosts(listOfPosts.map((post) =>{
        if (post.id === postId) {
          if (response.data.liked) {
            return { ...post, Likes: [...post.Likes, 0] };
          } else {
            const likesArray = post.Likes;
            likesArray.pop();
            return { ...post, Likes: likesArray };
          }
        } else {
          return post;
        }
      })
      );
     if(likedPosts.includes(postId)){
      setLikedPosts(likedPosts.filter((id) => {
        return id !== postId;
      }));
     }else{
      setLikedPosts([...likedPosts, postId]);
     }
    });
  };

    return (
        <div>
         {listOfPosts.map((value, key) => {
    
          return (
             <div className='post' key={key} >
               <div className='title'>{value.title}</div>
               <div className='body' onClick={() => {history(`/post/${value.id}`)}}>{value.postText}</div>
               <div className='footer'>
               <div className="username"onClick={() => {history(`/profile/${value.UserId}`)}}>{value.username}</div>
               <div className="buttons">
               

               <ThumbUpAltIcon onClick={ () => {likePost(value.id)}} 
               className={likedPosts.includes(value.id) ? "unlikeBttn" : "likeBttn"} />
              
               <label>{value.Likes.length}</label>
               </div>
               </div>
               
             </div>
          );
         })}
        </div>
      );
}

export default Home